<template>
  <v-main style="background: white">
    <section class="support-bg">
      <div class="container-fluid px-0">
        <div class="row">
          <img src="../../assets/images/index/support.jpg" class="img-fluid" />
          <div class="col-md-12">
            <div class="col-md-12">
              <div class="wrap-content-main text-center text-white">
                <h1>
                  <span style="font-weight: 700">Support</span>
                  <span style="color: #e41e22; font-weight: 700"></span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="faq">
      <div class="container wrapper-container-container">
        <div class="row">
          <div class="col-12 text-center pt-5">
            <h1 class="head text-center">
              FAQ’s - Frequently
              <span style="color: #be090e">Asked Questions</span>
            </h1>
            <p class="para-head">
              Listed below are answers for some important questions users might
              have regarding Driver Updater.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-privacy">
      <div class="container wrapper-container">
        <div class="row">
          <div class="col-md-8 offset-md-2">
            <div class="mt-5-custom scroll-2">
              <!-- Add your scrollable content here -->
            </div>

            <div>
              <button
                class="accordion instruct-title"
                :class="{ active: isActive1 }"
                @click="toggleAccordion(1)"
              >
                <i class="fa fa-arrow-circle-down arrow-circel"></i>How to buy
                Printeroids driver updater?
              </button>
              <div v-show="isActive1" class="panel instruction">
                <h4>
                  Follow these instructions to register the software utility:
                </h4>
                <ul class="instruction">
                  <li>
                    Open the Printeroids Driver Updater on your PC browser.
                  </li>
                  <li>
                    Click the <strong>Buy Now</strong> button on the Homepage.
                  </li>
                  <li>
                    Confirm your cart value and contents on the next page. You
                    will also need to add additional details including payment
                    information and then click on
                    <strong>Buy Now</strong> button.
                  </li>
                  <li>
                    Once the transaction goes through, you’ll receive the
                    welcome mail along with a license key to put into the
                    software on your PC.
                  </li>
                </ul>
                <p class="support-para">
                  You will be registered on the software once the payment is
                  successfully processed. Now, you have access to use all the
                  functions and can update the outdated drivers to their latest
                  version on your computer.
                </p>
              </div>

              <button
                class="accordion instruct-title"
                :class="{ active: isActive2 }"
                @click="toggleAccordion(2)"
              >
                <i class="fa fa-arrow-circle-down arrow-circel"></i>How can I
                restore the drivers updated by Printeroids Software Updater?
              </button>
              <div v-show="isActive2" class="panel">
                <ul class="instruction">
                  <li>Open the Printeroids Driver Updater on your PC.</li>
                  <li>Go to the <strong>Restore tab</strong>.</li>
                  <li>
                    Click on the required backup of the driver, which was
                    automatically created on the date when the drivers were
                    updated.
                  </li>
                  <li>Click on the <strong>Load Backup</strong> button.</li>
                  <li>
                    To restore the drivers, select the checkboxes beside the
                    respective drivers.
                  </li>
                  <li>Click on the <strong>Restore</strong> button.</li>
                </ul>
                <p class="support-para">
                  Now, the previous drivers of the respective peripherals will
                  be reinstated on your computer.
                </p>
              </div>

              <button
                class="accordion instruct-title"
                :class="{ active: isActive3 }"
                @click="toggleAccordion(3)"
              >
                <i class="fa fa-arrow-circle-down arrow-circel"></i>How can we
                exclude the incompatible device drivers?
              </button>
              <div v-show="isActive3" class="panel">
                <ul class="instruction">
                  <li>
                    Click on the <strong>Scan Now</strong> button to detect the
                    status of the drivers.
                  </li>
                  <li>
                    Click on the <strong>Ignore</strong> option accessible below
                    the driver in the Scan results.
                  </li>
                </ul>
                <p class="support-para">
                  After excluding an incompatible device driver, it will not be
                  listed any further in the resulting scans and can’t be updated
                  anymore.
                </p>
              </div>

              <button class="accordion  instruct-title"  :class="{ active: isActive4 }"
              @click="toggleAccordion(4)">
                <i class="fa fa-arrow-circle-down arrow-circel"></i>How do I
                disable the Printeroids Driver updater from opening and running
                a scan when the computer starts?
              </button>
              <div v-show="isActive4" class="panel">
                <ul class="instruction">
                  <li>Open the Printeroids Driver Updater on your PC.</li>
                  <li>Go to the <strong>Settings</strong> tab.</li>
                  <li>Click on the <strong>General</strong> tab.</li>
                  <li>
                    Uncheck the box beside the “Launch at Windows start-up and
                    start a driver scan.”
                  </li>
                  <li>
                    Click on the <strong>Apply</strong> button in order to save
                    the changes.
                  </li>
                </ul>
              </div>

              <button class="accordion instruct-title" :class="{ active: isActive5 }"
              @click="toggleAccordion(5)">
                <i class="fa fa-arrow-circle-down arrow-circel"></i>Can I use
                the software without Automatic Subscription renewal?
              </button>
              <div v-show=isActive5 class="panel">
                <p class="support-para">
                  Yes, you can purchase the software without automatic
                  subscription renewal. However, it is best to use the Automatic
                  Renewal to ensure peace of mind and not worrying about missing
                  the updates.
                </p>
                <p class="support-para">
                  Simply, do not check the Automatic Renewal box on the purchase
                  page to buy the license for 1 year only.
                </p>
              </div>

              <button class="accordion instruct-title" :class="{ active: isActive6 }"
              @click="toggleAccordion(6)">
                <i class="fa fa-arrow-circle-down arrow-circel"></i>What are the
                advantages of Automatic Renewal?
              </button>
              <div v-show="isActive6" class="panel">
                <ul class="instruction">
                  <li>
                    You get all major and minor upgrades without any additional
                    cost.
                  </li>
                  <li>
                    Assurance of continuous coverage and not worrying about
                    missing the updates.
                  </li>
                  <li>
                    Your usage experience with your PC is always good because of
                    continuous driver updates.
                  </li>
                </ul>
              </div>

              <button class="accordion instruct-title" :class="{ active: isActive7 }"
              @click="toggleAccordion(7)">
                <i class="fa fa-arrow-circle-down arrow-circel"></i>Can I use
                the Printeroids Software Updater for a lifetime?
              </button>
              <div v-show="isActive7" class="panel">
                <p class="support-para">
                  Yes, you can use the software. You simply need to buy the
                  software with Automatic Renewal turned ON. It ensures
                  continuous protection and updates for your computer drivers.
                  Click <a href="cart.html" class="support-link">here</a> to buy
                  and enjoy a fabulous PC experience.
                </p>
              </div>

              <button class="accordion instruct-title" :class="{ active: isActive8 }"
              @click="toggleAccordion(8)">
                <i class="fa fa-arrow-circle-down arrow-circel"></i>Why is the
                Printeroids Driver updater not updating the drivers on my PC?
              </button>
              <div v-show="isActive8" class="panel">
                <ul class="instruction">
                  <li>
                    You need the full version of Printeroids Driver Updater to
                    update the outdated drivers. In the free trial version, the
                    software only scans and shows the outdated drivers for you
                    to inspect. Please buy the product key to update the
                    outdated drivers to their latest version. Click
                    <a href="cart.html" class="support-link">here</a> to buy.
                  </li>
                </ul>
              </div>
              <button class="accordion instruct-title"  :class="{ active: isActive9 }"
              @click="toggleAccordion(9)">
                <i class="fa fa-arrow-circle-down arrow-circel"></i>How do I
                uninstall Printeroids Driver Updater from my PC?
              </button>
              <div v-show="isActive9" class="panel">
                <p class="support-para">
                  We are sorry to see you go. Please refer to the uninstall
                  instructions
                  <a href="uninstallinstructions.html" class="support-link"
                    >here</a
                  >.
                </p>
              </div>

              <button class="accordion instruct-title"  :class="{ active: isActive10 }"
              @click="toggleAccordion(10)"> 
                <i class="fa fa-arrow-circle-down arrow-circel"></i>How can I
                get a refund?
              </button>
              <div v-show="isActive10" class="panel">
                <p class="support-para">
                  All refund requests are governed by our
                  <a href="refund-policy.html" class="support-link"
                    >refund policy</a
                  >
                  mentioned on our website. Each request shall be processed in
                  adherence to the said policy.
                </p>
              </div>

              <button class="accordion instruct-title"  :class="{ active: isActive11 }"
              @click="toggleAccordion(11)">
                <i class="fa fa-arrow-circle-down arrow-circel"></i>Can I buy
                your products without subscription?
              </button>
              <div v-show="isActive11" class="panel">
                <ul class="instruction">
                  <li>
                    Yes, you can purchase our products without automatic
                    subscription renewal. To do so, do not check the Automatic
                    Renewal box on the purchase page. However, the product will
                    stop working after one year, and you will have to purchase a
                    new license key again to continue using the product.
                  </li>
                </ul>
              </div>

              <button class="accordion instruct-title"  :class="{ active: isActive12 }"
              @click="toggleAccordion(12)">
                <i class="fa fa-arrow-circle-down arrow-circel"></i>What is the
                difference between 1 Year Upgrade Protection and Continuous
                Upgrade Protection?
              </button>
              <div v-show="isActive12" class="panel">
                <ul class="instruction">
                  <li>
                    Under 1 Year Upgrade Protection, all upgrades are provided
                    for free for one year. Also, the product will stop working
                    after one year, and you will have to purchase it again.
                    However, under Continuous Upgrade Protection, you are billed
                    each year automatically, and all upgrades are free for the
                    duration of your subscription.
                  </li>
                </ul>
              </div>

              <button class="accordion instruct-title"  :class="{ active: isActive13 }"
              @click="toggleAccordion(13)">
                <i class="fa fa-arrow-circle-down arrow-circel"></i>What will
                happen after 1 year if I select 1 Year Upgrade Protection?
              </button>
              <div v-show="isActive13" class="panel">
                <ul class="instruction">
                  <li>
                    The product will stop working after one year, and you will
                    have to purchase a new license key again to continue using
                    the product.
                  </li>
                </ul>
              </div>

              <button class="accordion instruct-title"  :class="{ active: isActive14 }"
              @click="toggleAccordion(14)">
                <i class="fa fa-arrow-circle-down arrow-circel"></i>I
                accidentally purchased the product with a subscription. How can
                I cancel it?
              </button>
              <div v-show="isActive14" class="panel">
                <ul class="instruction">
                  <li>
                    Please contact us at
                    <a
                      href="mailto:support@printeroids.com"
                      class="support-link"
                      >support@printeroids.com</a
                    >
                    and we will assist you with the cancellation of your
                    subscription.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </v-main>
</template>
<script>
export default {
  name: "SupportPage",
  data() {
    return {
      isActive1: false,
      isActive2: false,
      isActive3: false,
      isActive4: false,
      isActive5: false,
      isActive6: false,
      isActive7: false,
      isActive8: false,
      isActive9: false,
      isActive10: false,
      isActive11: false,
      isActive12: false,
      isActive13: false,
      isActive14: false,
    };
  },
  methods: {
    toggleAccordion(index) {
      console.log("ee");
      this.isActive1 = index === 1 ? !this.isActive1 : false;
      this.isActive2 = index === 2 ? !this.isActive2 : false;
      this.isActive3 = index === 3 ? !this.isActive3 : false;
      this.isActive4 = index === 4 ? !this.isActive4 : false;
      this.isActive5 = index === 5 ? !this.isActive5 : false;
      this.isActive6 = index === 6 ? !this.isActive6 : false;
      this.isActive7 = index === 7 ? !this.isActive7 : false;
      this.isActive8 = index === 8 ? !this.isActive8 : false;
      this.isActive9 = index === 9 ? !this.isActive9 : false;
      this.isActive10 = index === 10 ? !this.isActive10 : false;
      this.isActive11 = index === 11 ? !this.isActive11 : false;
      this.isActive12 = index === 12 ? !this.isActive12 : false;
      this.isActive13 = index === 13 ? !this.isActive13 : false;
      this.isActive14 = index === 14 ? !this.isActive14 : false;
    },
  },
};
</script>
<style scoped>
.panel {
  display: block; /* Panels are shown when active */
}
</style>
