import store from '@/store'
import axios from 'axios'

const baseURL = 'http://localhost:8080';
// const baseURL = 'http://api.choosecab.aaibuzz.com';
// const baseURL = 'http://192.168.1.105:8080';
// const baseURL = 'http://172.20.10.2:8080';
// const baseURL = 'http://192.168.1.129:8080';
// const baseURL = 'http://api.hrsathi.aaibuzz.com'
axios.defaults.baseURL = baseURL + '/api';
store.dispatch('settings/setBaseUrl', baseURL)
store.dispatch('settings/setMediaUrl', 'https://az-do-bucket-1.blr1.digitaloceanspaces.com/')

// declare a request interceptor
axios.interceptors.request.use(config => {
  store.dispatch('errors/setErrors', {})
  store.dispatch('errors/setStatus', {})
  return config
}, error => {
  return Promise.reject(error)
})

// declare a response interceptor
axios.interceptors.response.use((response) => {
  return response
}, error => {
  const errors = error.response.data.errors
  Object.keys(errors).forEach((key) => {
    errors[key] = errors[key][0]
  })
  store.dispatch('errors/setErrors', errors)
  store.dispatch('errors/setStatus', error.response.status)
  return Promise.reject(error)
})