import Vue from "vue";
import moment from "moment";

Vue.filter("hhmm", function (value) {
  if (!value) return "";
  value = value.toString();
  return moment(value, ["HH:mm:ss"]).format("hh:mm A");
});
Vue.filter("HM", function (value) {
  if (!value) return "";
  value = value.toString();
  return moment(value).format("hh:mm A");
});

Vue.filter("ddmmyyyy", function (value) {
  if (!value) return "";
  value = value.toString();
  return moment(value).format("DD-MM-YYYY");
});
Vue.filter("encrypt", function (value) {
  if (!value) return "";
  const CryptoJS = require("crypto-js");
  const secretKey = CryptoJS.enc.Utf8.parse("12345678123456781234567812345678");
  const iv = CryptoJS.enc.Utf8.parse("Ef7ix7ETPgghl3vP");
  let encrypted = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(value),
    secretKey,
    { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
  ).toString();
  return encrypted.replace(/[+]/g, "-").replace(/[/]/g, "_");
});
Vue.filter("capitalize", function (string = "") {
  // if (string.length > 25) return string.substring(0, 25) + "...";
  if (string) {
    let capitalized_string =
      string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    return capitalized_string;
  }
});
Vue.filter("secToHhMm", function (value) {
  if (value == "" || value == null) return "00:00:00";
  var sec_num = parseInt(value, 10);
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor(sec_num / 60) % 60;
  var seconds = sec_num % 60;

  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? (v == 0 ? "00" : "0" + v) : v))
    .join(":");
});
