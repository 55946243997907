import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// Layout
import UnAuthLayout from "../views/layouts/unauth-layout.vue";
import AuthLayout from "../views/layouts/auth-layout.vue";

// Static
import HomePage from "../views/home/index.vue";
import SupportPage from "../views/home/support.vue";
import DriverPage from "../views/home/driver.vue";
// import TestPage from "../views/downloads/test.vue";

//downloads
import PrintersDownloadPage from "../views/downloads/printer.vue";
import AudiosDownloadPage from "../views/downloads/audio.vue";
import SystemDownloadPage from "../views/downloads/system.vue";
import UsbDownloadPage from "../views/downloads/usb.vue";
import HardwareDownloadPage from "../views/downloads/hardware.vue";

// Auth
import LoginPage from "../views/auth/login.vue";
import ForgotPasswordPage from "../views/auth/forgot-password.vue";

//privacy policies
import PrivacyPolicyPage from "../views/policies/privacy-policy.vue";
import AboutUsPage from "../views/policies/about-us.vue";
import RefundPolicyPage from "../views/policies/refund-policy.vue";
import TermsAndConditionsPage from "../views/policies/terms-conditions.vue";
import DmcaPage from "../views/policies/dmca.vue";
import ContactUsPage from "../views/policies/contact-us.vue";

//blogs
import BlogsPage from "../views/blogs/index.vue";
import Blogs1Page from "../views/blogs/blog1.vue";
import Blogs2Page from "../views/blogs/blog2.vue";
import Blogs3Page from "../views/blogs/blog3.vue";
import Blogs4Page from "../views/blogs/blog4.vue";
import Blogs5Page from "../views/blogs/blog5.vue";
import Blogs6Page from "../views/blogs/blog6.vue";

const routes = [
  // AuthLayout
  {
    path: "/auth",
    name: "AuthLayout",
    component: AuthLayout,
    children: [

    ],
  },
  // End AuthLayout

  // UnAuthLayout
  {
    path: "/unauth",

    name: "UnAuthLayout",
    component: UnAuthLayout,
    children: [
      {
        path: "/login",
        name: "LoginPage",
        component: LoginPage,
      },
      {
        path: "/home",
        name: "HomePage",
        component: HomePage,
      },
      {
        path: "/support",
        name: "SupportPage",
        component: SupportPage,
      },
      // {
      //   path: "/test",
      //   name: "TestPage",
      //   component: TestPage,
      // },
      {
        path: "/forgot-password",
        name: "ForgotPasswordPage",
        component: ForgotPasswordPage,
      },
      {
        path: "/download-sound-driver",
        name: "AudiosDownloadPage",
        component: AudiosDownloadPage,
      },
      {
        path: "/download-system-driver",
        name: "SystemDownloadPage",
        component: SystemDownloadPage,
      },
      {
        path: "/download/usb-devices",
        name: "UsbDownloadPage",
        component: UsbDownloadPage,
      },
      {
        path: "/download/hardware-devices",
        name: "HardwareDownloadPage",
        component: HardwareDownloadPage,
      },
      {
        path: "/download-system-driver",
        name: "SystemDownloadPage",
        component: SystemDownloadPage,
      },
      {
        path: "/drivers",
        name: "DriverPage",
        component: DriverPage,
      },
      {
        path: "/download-printer-driver",
        name: "PrintersDownloadPage",
        component: PrintersDownloadPage,
      },
      {
        path: "/privacy-policies",
        name: "PrivacyPolicyPage",
        component: PrivacyPolicyPage,
      },
      {
        path: "/about-us",
        name: "AboutUsPage",
        component: AboutUsPage,
      },
      {
        path: "/refund-policy",
        name: "RefundPolicyPage",
        component: RefundPolicyPage,
      },
      {
        path: "/terms-conditions",
        name: "TermsAndConditionsPage",
        component: TermsAndConditionsPage,
      },
      {
        path: "/dmca",
        name: "DmcaPage",
        component: DmcaPage,
      },
      {
        path: "/blogs",
        name: "BlogsPage",
        component: BlogsPage,
      },
      {
        path: "/blogs/bsod-or-the-blue-screen-of-death",
        name: "Blogs1Page",
        component: Blogs1Page,
      },
      {
        path: "/blogs/for-a-faster-pc-update-your-drivers",
        name: "Blogs2Page",
        component: Blogs2Page,
      },
      {
        path: "/blogs/experiencing-issues-with-a-slow-pc",
        name: "Blogs3Page",
        component: Blogs3Page,
      },
      {
        path: "/blogs/stop-having-problems-with-hardware-upgrades",
        name: "Blogs4Page",
        component: Blogs4Page,
      },
      {
        path: "/blogs/introduction-to-windows-task-manager",
        name: "Blogs5Page",
        component: Blogs5Page,
      },
      {
        path: "/blogs/want-to-boost-your-internet-connection-speed",
        name: "Blog6Page",
        component: Blogs6Page,
      },
      {
        path: "/contact-us",
        name: "ContactUsPage",
        component: ContactUsPage,
      },
    ],
  },
  //End UnAuthLayout
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === null) {
    return next({
      name: "HomePage",
    });
  }
  next();
});


export default router;
