<template>
  <v-main style="background: white">
    <section class="only-desktop">
      <div class="container-fluid px-0">
        <div class="row">
          <div class="col-12 p-0">
            <div
              id="carouselExampleIndicators"
              class="carousel slide"
              data-ride="carousel"
            >
              <ol class="carousel-indicators">
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="0"
                  class="active"
                ></li>
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="1"
                ></li>
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="2"
                ></li>
              </ol>

              <div class="carousel-inner mobile-carousel-inner">
                <div class="carousel-item active">
                  <img
                    class="d-block w-100"
                    src="../../assets/images/driverIcons/image1.png"
                    alt="first-Drivers"
                  />
                  <div class="DriverCenter">
                    <div class="col-md-6">
                      <div class="rap-content d-flex">
                        <span class="mr-3">
                          <v-icon style="color: #0074d9" size="122">
                            mdi-printer
                          </v-icon></span
                        >
                        <span class="rap-computer"
                          >Printeroids <br />
                          <span class="rap-Driver">Drivers update</span></span
                        >
                      </div>
                      <p class="mt-4">
                        Drivers for all your devices PC Performance
                      </p>
                      <div class="rap-ul">
                        <ul class="bull-block p-0">
                          <li>
                            <i
                              class="fa fa-check-square-o"
                              aria-hidden="true"
                            ></i>
                            &nbsp;Update Outdated Drivers
                          </li>
                          <li>
                            <i
                              class="fa fa-check-square-o"
                              aria-hidden="true"
                            ></i>
                            &nbsp;Backup And Restore Drivers
                          </li>
                          <li>
                            <i
                              class="fa fa-check-square-o"
                              aria-hidden="true"
                            ></i>
                            &nbsp;Schedule Driver Scan
                          </li>
                        </ul>
                      </div>
                      <div class="rap-verified">
                        <a href="#footerSeel" style="text-decoration: none">
                          <img
                            src="../../assets/images/norton-img.png"
                            width="130"
                            alt="Printeroids Driver Updater"
                          />&nbsp;&nbsp;&nbsp;&nbsp;
                          <img
                            src="../../assets/images/digicirt-img.png"
                            width="130"
                            alt="Printeroids Driver Updater"
                          />&nbsp;&nbsp;&nbsp;&nbsp;
                          <img
                            src="../../assets/images/appsteem-logo-1.png"
                            width="65"
                            alt="Printeroids Driver Updater"
                          />
                        </a>
                        <a
                          href="#footerSeel"
                          style=""
                          class="ComputeroidsSeal2x"
                        >
                          <img
                            class="new_Seal"
                            alt="CompliAssure SiteSeal"
                            src="https://www.rapidscansecure.com/siteseal/Seal.aspx?code=17,43B3F09F5E4FFF727FE32F53FC4BDCB48616210E"
                            border="0"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item mobile-carousel-inner">
                  <img
                    class="d-block w-100"
                    src="../../assets/images/driverIcons/image2.png"
                    alt="Second Drivers"
                  />
                  <div class="DriverCenter">
                    <div class="col-md-6">
                      <div class="rap-content d-flex">
                        <span class="mr-3">
                          <v-icon style="color: #0074d9" size="122">
                            mdi-printer
                          </v-icon></span
                        >
                        <span class="rap-computer"
                          >Printeroids <br />
                          <span class="rap-Driver">Drivers update</span></span
                        >
                      </div>
                      <p class="mt-4">
                        Drivers for all your devices PC Performance
                      </p>
                      <div class="rap-ul">
                        <ul class="bull-block p-0">
                          <li>
                            <i
                              class="fa fa-check-square-o"
                              aria-hidden="true"
                            ></i>
                            &nbsp;Update Outdated Drivers
                          </li>
                          <li>
                            <i
                              class="fa fa-check-square-o"
                              aria-hidden="true"
                            ></i>
                            &nbsp;Backup And Restore Drivers
                          </li>
                          <li>
                            <i
                              class="fa fa-check-square-o"
                              aria-hidden="true"
                            ></i>
                            &nbsp;Schedule Driver Scan
                          </li>
                        </ul>
                      </div>

                      <div class="rap-verified">
                        <a href="#footerSeel" style="text-decoration: none">
                          <img
                            src="../../assets/images/norton-img.png"
                            width="130"
                            alt="Printeroids Driver Updater"
                          />&nbsp;&nbsp;&nbsp;&nbsp;
                          <img
                            src="../../assets/images/digicirt-img.png"
                            width="130"
                            alt="Printeroids Driver Updater"
                          />&nbsp;&nbsp;&nbsp;&nbsp;
                          <img
                            src="../../assets/images/appsteem-logo-1.png"
                            width="90"
                            alt="Printeroids Driver Updater"
                          />
                        </a>
                        <a
                          href="#footerSeel"
                          style=""
                          class="ComputeroidsSeal2x"
                        >
                          <img
                            class="new_Seal"
                            alt="CompliAssure SiteSeal"
                            src="https://www.rapidscansecure.com/siteseal/Seal.aspx?code=17,43B3F09F5E4FFF727FE32F53FC4BDCB48616210E"
                            border="0"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item mobile-carousel-inner">
                  <img
                    class="d-block w-100"
                    src="../../assets/images/driverIcons/image3.png"
                    alt="Third Drivers"
                  />
                  <div class="DriverCenter">
                    <div class="col-md-6">
                      <div class="rap-content d-flex">
                        <span class="mr-3">
                          <v-icon style="color: #0074d9" size="122">
                            mdi-printer
                          </v-icon></span
                        >
                        <span class="rap-computer"
                          >Printeroids <br />
                          <span class="rap-Driver">Drivers update</span></span
                        >
                      </div>
                      <p class="mt-4">
                        Drivers for all your devices PC Performance
                      </p>

                      <div class="rap-ul">
                        <ul class="bull-block p-0">
                          <li>
                            <i
                              class="fa fa-check-square-o"
                              aria-hidden="true"
                            ></i>
                            &nbsp;Update Outdated Drivers
                          </li>
                          <li>
                            <i
                              class="fa fa-check-square-o"
                              aria-hidden="true"
                            ></i>
                            &nbsp;Backup And Restore Drivers
                          </li>
                          <li>
                            <i
                              class="fa fa-check-square-o"
                              aria-hidden="true"
                            ></i>
                            &nbsp;Schedule Driver Scan
                          </li>
                        </ul>
                      </div>

                      <div class="rap-verified">
                        <a href="#footerSeel" style="text-decoration: none">
                          <img
                            src="../../assets/images/norton-img.png"
                            width="130"
                            alt="Printeroids Driver Updater"
                          />&nbsp;&nbsp;&nbsp;&nbsp;
                          <img
                            src="../../assets/images/digicirt-img.png"
                            width="130"
                            alt="Printeroids Driver Updater"
                          />&nbsp;&nbsp;&nbsp;&nbsp;
                          <img
                            src="../../assets/images/appsteem-logo-1.png"
                            width="90"
                            alt="Printeroids Driver Updater"
                          />
                        </a>
                        <a
                          href="#footerSeel"
                          style=""
                          class="ComputeroidsSeal2x"
                        >
                          <img
                            class="new_Seal"
                            alt="CompliAssure SiteSeal"
                            src="https://www.rapidscansecure.com/siteseal/Seal.aspx?code=17,43B3F09F5E4FFF727FE32F53FC4BDCB48616210E"
                            border="0"
                        /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a
                class="carousel-control-prev"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Previous</span>
              </a>
              <a
                class="carousel-control-next"
                href="#carouselExampleIndicators"
                role="button"
                data-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="my-primary">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center text-white">
            <div class="vpDrive">
              <p class="mb-0">Select Device for Printeroids Drivers</p>
            </div>
          </div>
        </div>
        <nav class="navbar navbar-expand-lg navbar-dark p-0">
          <div class="wp-driversBg text-center" id="navbarSupportedContent">
            <ul class="navbar-nav mc-auto border-nav">
              <li class="nav-item">
                <a class="nav-link" href="download-printer-driver">
                  <img
                    src="../../assets/images/driverIcons/printer.png"
                    class="img-fluid"
                  /><br />
                  <a href="download-printer-driver" class="btn DriversBtn mt-4"
                    >Printers Drivers</a
                  >
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="download-sound-driver">
                  <img
                    src="../../assets/images/driverIcons/sound-drivers.png"
                    class="img-fluid"
                  /><br />
                  <a href="download-sound-driver" class="btn DriversBtn mt-4"
                    >Sound Drivers</a
                  >
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="download-system-driver">
                  <img
                    src="../../assets/images/driverIcons/system-drivers.png"
                    class="img-fluid"
                  /><br />
                  <a href="download-system-driver" class="btn DriversBtn mt-4"
                    >System Drivers</a
                  >
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="download/usb-devices">
                  <img
                    src="../../assets/images/driverIcons/port.png"
                    class="img-fluid"
                  /><br />
                  <a href="download/usb-devices" class="btn DriversBtn mt-4"
                    >USB Drivers</a
                  >
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="download/hardware-devices">
                  <img
                    src="../../assets/images/driverIcons/hardware.png"
                    class="img-fluid"
                  /><br />
                  <a
                    href="download/hardware-devices"
                    class="btn DriversBtn mt-4"
                    >Hardware Drivers</a
                  >
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </section>
    <section class="home-section">
      <div class="container">
        <div class="row">
          <div class="col-12 space2 text-center">
            <h2 class="head headCom text-center">
              Why Choose <span style="color: #be090e">Printeroids </span>Driver
              Updater?
            </h2>
            <p class="mt-4">
              With Printeroids Driver Updater, you can enhance the performance
              of your<br />
              system in the most efficient manner with its easy to use built-in
              features.
            </p>
          </div>
        </div>
      </div>
    </section>
    <div class="container space2">
      <div class="row text-center">
        <div class="col-md-12 mb-5">
          <p class="GetStartedBg">
            Get Started, Select Your Windows Version to Download
          </p>
        </div>
      </div>
      <div class="downloadSec">
        <div class="row text-center downloadExeCon" v-if="!formVisible">
          <div class="col-md-3 downloadCon">
            <div class="DriveBox">
              <img src="../../assets/images/window11.png" class="img-fluid" />
              <h4 class="pt-2">Windows 11</h4>
              <hr class="" />
              <a @click="formVisible = true" class="window-button mb-5"
                >Download Now</a
              >
            </div>
          </div>
          <div class="col-md-3 downloadCon">
            <div class="DriveBox">
              <img src="../../assets/images/window1.png" class="img-fluid" />
              <h4 class="pt-2">Windows 10</h4>
              <hr class="" />
              <a @click="formVisible = true" class="window-button mb-5"
                >Download Now</a
              >
            </div>
          </div>

          <div class="col-md-3 downloadCon">
            <div class="DriveBox">
              <img src="../../assets/images/window2.png" class="img-fluid" />
              <h4 class="pt-2">Windows 8</h4>
              <hr class="" />
              <a @click="formVisible = true" class="window-button mb-5"
                >Download Now</a
              >
            </div>
          </div>

          <div class="col-md-3 downloadCon">
            <div class="DriveBox">
              <img src="../../assets/images/window3.png" class="img-fluid" />
              <h4 class="pt-2">Windows 7</h4>
              <hr class="" />
              <a @click="formVisible = true" class="window-button mb-5"
                >Download Now</a
              >
            </div>
          </div>
        </div>
        <div v-if="formVisible" class="container mt-4 modelNumberFormCon">
          <div class="row">
            <div class="col-md-6">
              <form @submit.prevent="submitForm" class="_bCon">
                <div class="form-group">
                  <h4>Download Driver</h4>
                  <hr />
                </div>
                <div class="form-group">
                  <label for="model_number"
                    >Model Number <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="model_number"
                    v-model="formData.model_number"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="name"
                    >Name <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    v-model="formData.name"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="phone"
                    >Contact Number <span class="text-danger">*</span></label
                  >
                  <div class="input-group mb-3">
                    <select
                      class="custom-select"
                      id="inputGroupSelect01"
                      v-model="formData.country"
                    >
                      <option value="US">US</option>
                      <option value="UK">UK</option>
                      <option value="Canada">Canada</option>
                    </select>
                    <input
                      type="text"
                      autocomplete="off"
                      class="form-control OnlyNumberInput"
                      minlength="10"
                      id="phone"
                      v-model="formData.phone"
                      style="width: 77%"
                      required
                    />
                  </div>
                </div>

                <v-btn
                  @click="submitForm"
                  :disabled="isValidNumber == false"
                  class="btn"
                  color="green"
                  :dark="isValidNumber"
                >
                  Submit & Download
                </v-btn>
              </form>

              <div
                class="_bCon loaderCon hidden pt-5 text-center"
                v-if="isLoading"
              >
                <img
                  src="https://computeroids.com/assets/images/wait.gif"
                  class="img-fluid"
                  width="100"
                /><br /><br />
                <p>Please wait..</p>
              </div>
            </div>
            <div class="col-md-6">
              <span class="btnCloseDownload" @click="formVisible = false"
                >x</span
              >
              <a class="navbar-brand" href="index.html">
                <img
                  src="../../assets/png/logo-no-background.png"
                  class="img-fluid header-logo" style="width: 500px; margin-top: 100px"
                  alt="Printeroids"
                />
              </a>
              <!-- <img src="../../assets/computeroids.gif" class="img-fluid" /> -->
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 text-center mt-3">
          <p class="font-size1 ptag">
            Printeroids Driver Updater is an independent service provider for
            software products. It is a tool to help identify out-of-date or
            missing device drivers. Refer to our
            <a href="software-principles.html">Software Principles</a>.
          </p>
          <p class="font-size1 ptag">
            By downloading you accept the
            <a href="privacy-policy.html">Privacy Policy</a> and
            <a href="terms-of-use.html">Terms and Conditions</a>. Full
            functionality requires $9.99 monthly subscription.
          </p>
          <p class="font-size1 ptag">
            Use of names and trademarks are for reference only and no
            affiliation is implied with any named third-party companies.
          </p>
        </div>
      </div>
    </div>
    <section class="home-section">
      <div class="container mb-5">
        <div class="row space2">
          <div class="col-md-6">
            <p>
              Print, scan, import files. Play crystal clear videos and make
              crackle-free voice calls. Printeroids Driver Updater auto-scans
              and updates your drivers to reduce and prevent problems with:
            </p>
            <div class="row">
              <div class="col-md-6">
                <ul class="list p-0 l-height mb-5">
                  <li>
                    <img
                      src="../../assets/images/icon/tick.jpg"
                      class="img-fluid"
                    />&nbsp;&nbsp;Photo and video cameras
                  </li>
                  <li>
                    <img
                      src="../../assets/images/icon/tick.jpg"
                      class="img-fluid"
                    />&nbsp;&nbsp;Printers and scanners
                  </li>
                  <li>
                    <img
                      src="../../assets/images/icon/tick.jpg"
                      class="img-fluid"
                    />&nbsp;&nbsp;Headphones and speakers
                  </li>
                </ul>
              </div>

              <div class="col-md-6">
                <ul class="list p-0 l-height rightS-mobile">
                  <li>
                    <img
                      src="../../assets/images/icon/tick.jpg"
                      class="img-fluid"
                    />&nbsp;&nbsp;Mouse and keyboards
                  </li>
                  <li>
                    <img
                      src="../../assets/images/icon/tick.jpg"
                      class="img-fluid"
                    />&nbsp;&nbsp;Monitors and Wifi routers
                  </li>
                  <li>
                    <img
                      src="../../assets/images/icon/tick.jpg"
                      class="img-fluid"
                    />&nbsp;&nbsp;and other external devices
                  </li>
                </ul>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <a href="drivers" class="home_down D-down mobile-res">
                  Download Now</a
                >
              </div>
            </div>
          </div>

          <div class="col-md-6 text-right SoftwareRight">
            <img
              src="../../assets/images/image4.png"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="outdatedBg">
      <div class="container">
        <div class="row">
          <div class="col-md-8 p-0">
            <img
              src="../../assets/images/image5.png"
              class="img-fluid outpad"
              alt="Updates Outdated System Driver"
            />
          </div>
          <div class="col-md-4 mb-pd">
            <div class="bg-up2 p-3">
              <h2 class="outdatedHead">Updates Outdated System Drivers</h2>
              <p class="pt-3">
                If your system’s drivers are missing or outdated, it can be
                susceptible to errors. Driver Updater scans your computer for
                missing, corrupt or outdated drivers. It allows you to update or
                fix all drivers at once, therefore reducing the hassle to update
                them individually.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container space2">
        <div class="row">
          <div class="col-md-5 pt-2">
            <div class="">
              <h2 class="outdatedHead pt-4">
                Considerably less crashing or <br />freezing
              </h2>
              <p class="pt-3">
                Top reasons for crashes, freezes, and bluescreens are corrupted
                and outdated drivers. Printeroids Driver Updater finds the
                latest versions of the driver stable experience, lesser
                connection problems, mouse or printer issues.
              </p>
            </div>
          </div>
          <div class="col-md-7">
            <img
              src="../../assets/images/crashing.jpg"
              class="img-fluid"
              alt="Driver Updater"
            />
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container space2">
        <div class="row">
          <div class="col-md-6 p-0 fix-mobile">
            <h2 class="headfix pt-3">
              Fix
              <span
                style="
                  background: #000;
                  padding: 4px 30px 4px 30px;
                  background: #ffd400;
                "
                >Sound</span
              >, printer and network issues on the go
            </h2>
            <div class="fixSolution d-flex mt-5">
              <img src="../../assets/images/icon-p.png" class="img-fluid" />
              <img
                src="../../assets/images/icon-sound.png"
                class="img-fluid ml-5"
              />
              <img
                src="../../assets/images/icon-network.png"
                class="img-fluid ml-5"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="ml-0">
              <p class="pt-3 pb-5">
                Issues like no sound coming from speakers, Wi-Fi constantly
                dropping, printer suddenly not working are resolved by
                Printeroids Driver Updater. Update your graphics drivers to
                boost performance and enjoy sharper images, be it games, Virtual
                Reality, multimedia apps, streaming or media editing.
              </p>
              <a href="drivers" class="home_down D-down"> Download Now</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="AppEsteem app-bg mt-5">
      <div class="container wrap-container">
        <div class="row">
          <div class="col-12">
            <h2 class="head text-center text-white pt-5">
              We are AppEsteem Certified
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 br-rg">
            <a
              href="https://customer.appesteem.com/certified?vendor=SAPPH"
              target="_blank"
            >
              <img
                src="../../assets/images/wb_img/image6.png"
                class="img-fluid app-size"
                style="width: 82%"
                alt="Printeroids Driver Updater"
            /></a>
          </div>
          <div class="col-md-6">
            <div class="pl-4 pt-4 text-white Sol">
              <p>
                Printeroids is a leading IT solutions and services
                organization, dedicated towards creating high-quality system
                optimization software and utilities. That’s what we focus on
                with this certification, both for products like Printeroids and
                services like Printeroids Customer Services.
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-left pt-3 pb-5">
            <a
              class="learn-mr"
              href="https://customer.appesteem.com/certified?vendor=SAPPH"
              target="_blank"
              >Learn More</a
            >
          </div>
        </div>
      </div>
    </section>
    <section class="download-computeroids drive-bg">
      <div class="container wrap-container">
        <div class="row pt-5">
          <div class="col-md-6 text-white">
            <h2 class="down-head">Download Printeroids Driver Updater</h2>
            <p class="text-white">
              An ultimate solution that helps enhance the performance of your
              Windows computer by eliminating driver and registry issues.
            </p>
            <div class="row mt-5">
              <div class="col-12">
                <a href="drivers" class="home_down D-down mobile-res">
                  Download Now</a
                >
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-ff">
            <!-- <img
              src="../../assets/images/wb_img/sdc-screen-laptop-1.png"
              class="img-fluid"
              style="float: left; width: 75%"
              alt="Download Printeroids Driver Updater"
            /> -->
          </div>
        </div>
        <div class="row">
          <div class="col-md-7">
            <p class="by-click">
              By clicking "Download Now", I agree to Site
              <a href="terms-of-use.html"> Terms of Use </a> and
              <a href="privacy-policy.html"> Privacy Policy</a> <br />Compatible
              OS: Windows 10/8.1/8/7 (32 bit/ 64 bit)
            </p>
          </div>
        </div>
      </div>
    </section>
  </v-main>
</template>
<script>
import axios from "axios";

export default {
  name: "SupportPage",
  data() {
    return {
      formVisible: false,
      isLoading: false,
      isValidNumber: false,
      formData: {
        model_number: "",
        name: "",
        phone: "",
        country: "US",
      },
    };
  },
  watch: {
    "formData.phone"(newPhone) {
      // Check if the phone number has 10 or more digits
      this.isValidNumber = /^\d{10,}$/.test(newPhone);
    },
  },
  mounted() {
    console.log("testing page");
  },
  methods: {
    async submitForm() {
      // Basic validation check
      if (
        !this.formData.model_number ||
        !this.formData.name ||
        !this.formData.phone
      ) {
        alert("All fields are required!");
        return;
      }
      if (this.isLoading) {
        console.log("Already submitting, please wait.");
        return;
      }
      this.isLoading = true;

      // Set up your API call to Zendesk
      const zendeskUrl = "https://printeroid.zendesk.com/api/v2/tickets.json";
      const email = "ishteyaqueali294@gmail.com"; // Replace with your Zendesk email
      const apiToken = "c2ENrfB99X69moZWTNGG5Mld1HsmyoSRqiABYvIC"; // Replace with your API token
      const authHeader = btoa(`${email}/token:${apiToken}`); // Base64 encoding for authentication

      try {
        const response = await axios.post(
          zendeskUrl,
          {
            ticket: {
              subject: `Ticket from ${this.formData.name}`,
              comment: {
                body: `Model Number: ${this.formData.model_number}\nContact: ${this.formData.phone}`,
              },
              requester: {
                name: this.formData.name,
                email: "customer@example.com", // Replace with dynamic customer email if available
              },
              priority: "normal",
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Basic ${authHeader}`,
            },
          }
        );
        console.log(response);
        this.isLoading = false;
      } catch (error) {
        this.$router.push("/drivers");
        console.error("Error creating ticket", error);
        this.isLoading = false;
      }
    },
    toggleAccordion(index) {
      console.log("ee");
      this.isActive1 = index === 1 ? !this.isActive1 : false;
      this.isActive2 = index === 2 ? !this.isActive2 : false;
      this.isActive3 = index === 3 ? !this.isActive3 : false;
      this.isActive4 = index === 4 ? !this.isActive4 : false;
      this.isActive5 = index === 5 ? !this.isActive5 : false;
      this.isActive6 = index === 6 ? !this.isActive6 : false;
      this.isActive7 = index === 7 ? !this.isActive7 : false;
      this.isActive8 = index === 8 ? !this.isActive8 : false;
      this.isActive9 = index === 9 ? !this.isActive9 : false;
      this.isActive10 = index === 10 ? !this.isActive10 : false;
      this.isActive11 = index === 11 ? !this.isActive11 : false;
      this.isActive12 = index === 12 ? !this.isActive12 : false;
      this.isActive13 = index === 13 ? !this.isActive13 : false;
      this.isActive14 = index === 14 ? !this.isActive14 : false;
    },
  },
};
</script>
<style scoped>
.panel {
  display: block; /* Panels are shown when active */
}
.app-bg {
  background-image: url("../../assets/images/drive2bg.jpg");
  background-position: center;
  background-size: cover;
}
</style>
