<template>
  <div style="font-family: Montserrat !important" id="inspire">
    <router-view />
    <FooterSection />

    <!-- Call Now Button -->
    <!-- <div class="clickToCallCon" @click="showModal">
      <p class="text" style="font-weight: bold; font-size: 18px; color: blue">
        <img src="./assets/images/click-to-call.gif" width="60px" />Call Us Now!
      </p>
    </div> -->

    <!-- Modal -->
    <!-- <div class="modal" v-if="isModalVisible" tabindex="-1">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Call Now</h5>
            <button type="button" class="close" @click="closeModal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <iframe
              src="https://control.phone.com/voip/call/us/01b1aa4bb558fd45a792b538966e62ef"
              title="Call Now"
              width="100%"
              height="300px"
              class="click_to_call_iframe"
            ></iframe>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
const CryptoJS = require("crypto-js");

export default {
  data() {
    return {
      isTabClosed: false,
      isModalVisible: true,
      logoutTimer: null, // Timer for automatic logout
      inactivityDuration: 10 * 60 * 1000, // 10 minutes in milliseconds
    };
  },
  mounted() {
    console.log("testing");
  },
  methods: {
    logout() {
      this.logOut();
      location.replace("/");
    },
    showModal() {
      console.log('clicked here call');
      
      this.isModalVisible = true; // Show the modal
    },
    closeModal() {
      this.isModalVisible = false; // Hide the modal
    },
    encryptIt(string) {
      let encrypted = CryptoJS.AES.encrypt(
        `${string}`,
        "secret key"
      ).toString();
      return encrypted;
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap");

.clickToCallCon {
  position: fixed;
  bottom: 600px !important;
  right: 65px !important;
  background: #ffc107;
  padding: 0px 12px;
  border-radius: 41px;
  cursor: pointer;
  z-index: 99;
}

.clickToCallCon p {
  margin-bottom: 0px;
}

.modal-content {
  margin-top: 87px;
}

.click_to_call_iframe {
  border: none;
}

.v-main {
  background: #f1f2f6;
}

.sr_no {
  border-top-left-radius: 4px !important;
}

.action_th {
  min-width: 115px;
}

tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}

.striped-table .table-row-even {
  background-color: #f5f5f5;
}

.striped-table .table-row-odd {
  background-color: #ffffff;
}

.striped-table .table-row-last {
  border-end-end-radius: 4px !important;
  /* border-radius-bottomleft: 4px !important; */
}
</style>
