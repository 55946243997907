<template>
  <v-main style="background: white">
    <section>
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <h1 class="head pt-5"><span style="color: #e31e24">Oops</span> Something Went Wrong!</h1>
            <h5 class="pt-2">
              <strong> Please Contact Us on <span style="color: #e31e24"> support@printeroids.com</span> or or chat with us.  </strong
              >
            </h5>
            <!-- <h6 class="mt-4 notice">
              <strong>Note:</strong> If your download did not start
              automatically, please
              <a href="trial-driver-download.html" class="click-com">
                click here.</a
              >
            </h6> -->
            <input type="hidden" id="activeStatus" value="0" />
            <!-- <h2 style="font-size:20px;" class="mt-2" >For any Query/Help Contact Us <br/><br/><strong><span>Call : 1-888-573-4222 <br/> Email : support@computeroids.com</span></strong></h2> -->
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-5">
            <p class="text-center hw">
              All rights reserved. Printeroids Driver Updater support, the
              Printeroids logo and Printeroids.com are trademarks of
              Printeroids and its affiliated companies. All third party
              products, brands or trademarks listed above are the sole property
              of their respective owner. No affiliation or endorsement is
              intended or implied. You may uninstall Driver Updater at any time,
              using the standard uninstall procedures as offered with your
              computer's Operation System, by accessing the computer's "Control
              Panel>Add/Remove Programs" folder, selecting 'Driver Updater' from
              the list of installed applications, and clicking the "Uninstall"
              button. OPERATING SYSTEMS: Compatible with 32/64 bit versions of
              Windows 10, 8, 7, & Vista. REQUIREMENTS: 25MB of hard disk space
              available, 256MB of RAM and at least a 300Mhz processor.
              *Printeroids full functionality requires subscription of
              $5.99/month (Prices and offers are subject to change) and provides
              accurate drivers in an easy and convenient method. Without a
              subscription, Driver Updater can assist your search for drivers at
              the respective manufacturers' website for free.
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- <section class=""> -->
    <section class="hidden">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 arrow-icon bounce2">
            <img
              src="../../assets/images/index/down-arrow2.png"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </section>
  </v-main>
</template>
<script>
export default {
  name: "DriversPage",
  data() {
    return {
      isDownLoad: false,
    };
  },
  mounted() {
    if (window.zE) {
      window.zE('webWidget', 'open');
    }
  },
};
</script>
