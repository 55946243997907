<template>
  <v-main style="background: white">
    <section class="">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="computeroids_download mt-2"></div>
            <div class="download-area text-center">
              <h2 class="pt-3 text-uppercase">
                <strong>Audio Driver Download</strong>
              </h2>

              <h5 class="text-center pt-2" style="color: #2b87f9">
                Get Started, Select Your Windows Version to Download
              </h5>
              <p class="pt-3 pb-3">
                An ultimate solution that helps enhance the performance of your
                Windows computer by eliminating driver and registry issues.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="downloadSec">
      <div class="container mt-4 downloadExeCon">
        <div class="row" v-if="!formVisible">
          <div class="col-md-3">
            <div class="computeroids_window text-center">
              <img
                src="../../assets/images/window11.png"
                class="img-fluid"
                style="width: 86px"
              />
              <h4 class="pt-2">Windows 11</h4>
              <hr class="" />
              <a @click="formVisible = true" class="window-button mb-5"
                >Download Now</a
              >
            </div>
          </div>
          <div class="col-md-3 col-xs-12">
            <div class="computeroids_window text-center">
              <img
                src="../../assets/images/w10150x150.png"
                class="img-fluid window_computeroids mt-3"
              />

              <h4 class="pt-2">Windows 10</h4>
              <hr class="" />

              <a @click="formVisible = true" class="window-button mb-5"
                >Download Now</a
              >
            </div>
          </div>

          <div class="col-md-3 col-xs-12 mb-ver_wrap">
            <div class="computeroids_window text-center">
              <img
                src="../../assets/images/w8-150x150.png"
                class="img-fluid window_computeroids mt-3"
              />
              <h4 class="pt-2">Windows 8</h4>
              <hr class="" />

              <a @click="formVisible = true" class="window-button mb-5"
                >Download Now</a
              >
            </div>
          </div>

          <div class="col-md-3 col-xs-12 mb-ver_wrap">
            <div class="computeroids_window text-center">
              <img
                src="../../assets/images/w7-150x150.png"
                class="img-fluid window_computeroids mt-3"
              />
              <h4 class="pt-2">Windows 7</h4>
              <hr class="" />

              <a @click="formVisible = true" class="window-button mb-5"
                >Download Now</a
              >
            </div>
          </div>
        </div>
        <div v-if="formVisible" class="container mt-4 modelNumberFormCon">
          <div class="row">
            <div class="col-md-6">
              <form @submit.prevent="submitForm" class="_bCon">
                <div class="form-group">
                  <h4>Download Driver</h4>
                  <hr />
                </div>
                <div class="form-group">
                  <label for="model_number"
                    >Model Number <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="model_number"
                    v-model="formData.model_number"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="name"
                    >Name <span class="text-danger">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    v-model="formData.name"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="phone"
                    >Contact Number <span class="text-danger">*</span></label
                  >
                  <div class="input-group mb-3">
                    <select
                      class="custom-select"
                      id="inputGroupSelect01"
                      v-model="formData.country"
                    >
                      <option value="US">US</option>
                      <option value="UK">UK</option>
                      <option value="Canada">Canada</option>
                    </select>
                    <input
                      type="text"
                      autocomplete="off"
                      class="form-control OnlyNumberInput"
                      minlength="10"
                      id="phone"
                      v-model="formData.phone"
                      style="width: 77%"
                      required
                    />
                  </div>
                </div>

                <v-btn
                  @click="submitForm"
                  :disabled="isValidNumber == false"
                  class="btn"
                  color="green"
                  :dark="isValidNumber"
                  :loading="isLoading"
                >
                  Submit & Download
                </v-btn>
              </form>

              <div
                class="_bCon loaderCon hidden pt-5 text-center"
                v-if="isLoading"
              >
                <img
                  src="https://computeroids.com/assets/images/wait.gif"
                  class="img-fluid"
                  width="100"
                /><br /><br />
                <p>Please wait..</p>
              </div>
            </div>
            <div class="col-md-6">
              <span class="btnCloseDownload" @click="formVisible = false"
                >x</span
              >
              <a class="navbar-brand" href="index.html">
                <img
                  src="../../assets/png/logo-no-background.png"
                  class="img-fluid header-logo" style="width: 500px; margin-top: 100px"
                  alt="Printeroids"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="row">
          <div class="col-md-12 pt-5">
            <p class="font-size1 ptag">
              Printeroids Driver Updater is an independent service provider for
              software products. It is a tool to help identify out-of-date or
              missing device drivers. Please refer to our
              <a href="software-principles.html">Software Principles</a> for our
              Software Policies. By downloading you accept the
              <a href="privacy-policy.html">Privacy Policy</a> and
              <a href="terms-of-use.html">Terms and Conditions</a>. Full
              functionality requires $9.99 monthly subscription. Use of names
              and trademarks are for reference only and no affiliation is
              implied with any named third-party companies
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center mt-5">
            <img
              src="../../assets/images/audioDrivers.jpg"
              class="img-fluid text-center mb-5"
            />
            <div class="">
              <p class="text-justify">
                Drivers are important software that your Computer needs to make
                devices such as Printers, Mouse, Keyboard, WebCamera, Audio,
                Media work efficiently & smoothly. While updating or
                reinstalling Operating System, hardware drivers often needs to
                update. This is a tiring and delicate process that Printeroids
                Driver Updater can help you to manage comfortably, by
                downloading recomended drivers.
              </p>
              <p class="text-justify">
                You must update device's drivers because obsolete drivers can
                slowdown the performance and can also cause other problems that
                might disturb the efficiency, speed and productivity while using
                your computer.
              </p>
              <p class="text-justify">
                Updating drivers with a latest version can speed up the
                performance drastically. However, it not recommended to update
                all the drivers by yourself and this should be done under
                professional's supervision.
              </p>
            </div>
            <div class="row mt-5">
          <div class="col-md-12">
            <h4><strong>Few Audio Drivers :-</strong></h4>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-4 text-center">
            <div class="realtecBox">
              <img src="../../assets/images/aud.png" class="img-fluid mb-3" />
              <p><strong>Realtek High Definition Audio CODEC</strong></p>
              <p>
                Realtek HD audio driver package includes: driver setup program,
                drivers for Windows 7/8/10.
              </p>
            </div>
          </div>

          <div class="col-md-4 text-center">
            <div class="realtecBox">
              <img src="../../assets/images/aud.png" class="img-fluid mb-3" />
              <p><strong>Sound Blaster X-Fi Series</strong></p>
              <p>
                Sound Blaster audio driver package includes: driver setup
                program, drivers for Windows 7/8/10.
              </p>
            </div>
          </div>

          <div class="col-md-4 text-center">
            <div class="realtecBox">
              <img src="../../assets/images/aud.png" class="img-fluid mb-3" />
              <p><strong>Creative Sound Blaster X-Fi Series.</strong></p>
              <p>
                Creative Sound Blaster audio driver package includes: driver
                setup program, drivers for Windows 7/8/10.
              </p>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-md-4 text-center">
            <div class="realtecBox">
              <img src="../../assets/images/aud.png" class="img-fluid mb-3" />
              <p><strong>Xonar DX Driver</strong></p>
              <p>
                Xonar audio driver package includes: driver setup program,
                drivers for Windows 7/8/10.
              </p>
            </div>
          </div>

          <div class="col-md-4 text-center">
            <div class="realtecBox">
              <img src="../../assets/images/aud.png" class="img-fluid mb-3" />
              <p><strong>Delta Series Digital Audio Card drivers</strong></p>
              <p>
                Delta Series audio driver package includes: driver setup
                program, drivers for Windows 7/8/10.
              </p>
            </div>
          </div>

          <div class="col-md-4 text-center">
            <div class="realtecBox">
              <img src="../../assets/images/aud.png" class="img-fluid mb-3" />
              <p><strong>SoundMAX Integrated Digital Audio</strong></p>
              <p>
                SoundMAX audio driver package includes: driver setup program,
                drivers for Windows 7/8/10.
              </p>
            </div>
          </div>
        </div>
          </div>
        </div>
      </div>
    </section>
  </v-main>
</template>
<script>
import axios from "axios";

export default {
  name: "DriversPage",
  data() {
    return {
      formVisible: false,
      isLoading: false,
      isValidNumber: false,
      formData: {
        model_number: "",
        name: "",
        phone: "",
        country: "US",
      },
    };
  },
  watch: {
    "formData.phone"(newPhone) {
      // Check if the phone number has 10 or more digits
      this.isValidNumber = /^\d{10,}$/.test(newPhone);
    },
  },
  mounted() {
    console.log("stil working", this.formVisible);

    // DOMContentLoaded logic moved here
    document.querySelectorAll(".toggleContent").forEach((element) => {
      element.addEventListener("click", function () {
        var target = this.getAttribute("data-target");
        var status = this.getAttribute("data-status");

        if (status == 0) {
          this.setAttribute("data-status", 1);
          document.querySelector(target).classList.remove("hidden");
          let icon = document.querySelector(target + " i"); // Changed to let
          if (icon) {
            icon.classList.remove("fa-plus-circle");
            icon.classList.add("fa-minus-circle");
          }
        } else {
          this.setAttribute("data-status", 0);
          document.querySelector(target).classList.add("hidden");
          let icon = document.querySelector(target + " i"); // Changed to let
          if (icon) {
            icon.classList.remove("fa-minus-circle");
            icon.classList.add("fa-plus-circle");
          }
        }
      });
    });
  },
  methods: {
    async submitForm() {
      // Basic validation check
      if (
        !this.formData.model_number ||
        !this.formData.name ||
        !this.formData.phone
      ) {
        alert("All fields are required!");
        return;
      }
      if (this.isLoading) {
        console.log("Already submitting, please wait.");
        return;
      }
      this.isLoading = true;

      // Set up your API call to Zendesk
      const zendeskUrl = "https://printeroid.zendesk.com/api/v2/tickets.json";
      const email = "ishteyaqueali294@gmail.com"; // Replace with your Zendesk email
      const apiToken = "c2ENrfB99X69moZWTNGG5Mld1HsmyoSRqiABYvIC"; // Replace with your API token
      const authHeader = btoa(`${email}/token:${apiToken}`); // Base64 encoding for authentication

      try {
        const response = await axios.post(
          zendeskUrl,
          {
            ticket: {
              subject: `Ticket from ${this.formData.name}`,
              comment: {
                body: `Model Number: ${this.formData.model_number}\nContact: ${this.formData.phone}`,
              },
              requester: {
                name: this.formData.name,
              },
              priority: "normal",
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Basic ${authHeader}`,
            },
          }
        );
        console.log(response);
        this.isLoading = false;
      } catch (error) {
        this.$router.push("/drivers");
        console.error("Error creating ticket", error);
        this.isLoading = false;
      }
    },
  },
};
</script>
