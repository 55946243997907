<template>
  <div>
    <div class="container-fluid" style="background: #ffb100">
      <div class="row">
        <div class="col-sm-12 py-3 text-center">
          <p class="font-size2 ptag" style="font-weight: 500">
            All third party products, brands or trademarks listed above are the
            sole property of their respective owner. No affiliation or
            endorsement is intended or implied.
          </p>
        </div>
      </div>
    </div>

    <footer class="Bg-Footer siteFooter">
      <div class="container wrap-container">
        <div class="row">
          <div class="col-md-4 footer-heading pt-5">
            <ul class="bull-block ftr-list">
              <li class="ftr-hrading">
                <a class="navbar-brand" href="index.html">
                  <div class="rap-content d-flex align-center">
                    <span >
                      <v-icon style="color: #0074d9" size="40">
                        mdi-printer
                      </v-icon>
                    </span>
                    <span style="font-size: 30px"> PRINTEROIDS </span>
                  </div>
                </a>
              </li>
              <li class="my-2">
                <span class="hv-tr" style="color: #e0e0e0; font-size: 14px">
                  <b><i class="fa fa-map-marker"></i> </b> 4365 S Mooney Blvd, Visalia, California, United States</span
                >
              </li>
              <li class="my-2">
                <span class="hv-tr" style="color: #e0e0e0; font-size: 14px">
                  <b><i class="fa fa-envelope"></i> </b>
                  support@printeroids.com</span
                >
              </li>
              <!-- <li class="my-2">
                <span class="hv-tr" style="color: #e0e0e0; font-size: 14px">
                  <small>Printeroids is a brand owned by</small><br />
                 </span>
              </li> -->
            </ul>
          </div>

          <div class="col-md-2 footer-heading pt-5 footerMenu">
            <h2 class="text-center"></h2>
            <ul class="bull-block ftr-list">
              <li class="ftr-hrading">Products</li>
              <li><a class="hv-tr" href="download.html">Try Now</a></li>
              <li><a class="hv-tr" href="cart.html">Buy Now</a></li>
              <li><a class="hv-tr" href="support">Support</a></li>
            </ul>
          </div>

          <div class="col-md-3 footer-heading pt-5 footerMenu">
            <h2 class="text-center"></h2>
            <ul class="bull-block ftr-list computeroids_up">
              <li class="ftr-hrading">About Us</li>
              <li><a class="hv-tr" href="about-us">About Us</a></li>
              <!-- <li>
                <a class="hv-tr" href="uninstallinstructions.html"
                  >Uninstall Instructions</a
                >
              </li> -->
              <li><a class="hv-tr" href="eula.html">EULA</a></li>
              <li><a class="hv-tr" href="contact-us">Contact Us</a></li>
            </ul>
          </div>

          <div class="col-md-3 footer-heading pt-5 footerMenu">
            <h2 class="text-center"></h2>
            <ul class="bull-block ftr-list">
              <li class="ftr-hrading">Policies</li>
              <li>
                <a class="hv-tr" href="software-principles.html"
                  >Software Principles</a
                >
              </li>
              <li>
                <a class="hv-tr" href="privacy-policies">Privacy Policy</a>
              </li>
              <li>
                <a class="hv-tr" href="refund-policy">Refund Policy</a>
              </li>
              <li>
                <a class="hv-tr" href="terms-conditions">Terms of Use</a>
              </li>
              <li>
                <a class="hv-tr" href="dmca">DMCA</a>
              </li>
            </ul>
          </div>
        </div>
        <!--//row-->
        <div class="row" id="footerSeel">
          <div class="col-sm-2">
            <!-- DigiCert Seal HTML -->
            <!-- Place HTML on your site where the seal should appear -->
            <div id="DigiCertClickID_0aprU8-T"></div>

            <!-- DigiCert Seal Code -->
            <!-- Place with DigiCert Seal HTML or with other scripts -->
          </div>
          <div class="col-sm-2 pt-2">
            <!-- DigiCert Seal HTML -->
            <!-- Place HTML on your site where the seal should appear -->
            <div id="DigiCertClickID_0aprU8-T"></div>
            <!-- DigiCert Seal Code -->
            <!-- Place with DigiCert Seal HTML or with other scripts -->

            <!-- end digicert -->
          </div>
          <div class="col-sm-2 qwerty">
              <img
                src="../../assets/images/appsteem-logo-1.png"
                class="img-fluid"
                width="100"
                alt="Printeroids Driver Updater"
            />
          </div>
          <div class="col-sm-4 qwerty">
              <img
                src="../../assets/images/payment-2.png"
                class="img-fluid"
                alt="Printeroids Driver Updater"
            />  
          </div>
        </div>
        <!--//row/ -->
      </div>
      <div class="container-fluid">
        <div class="row copy-bg">
          <div class="col-12 text-center">
            <p class="copyright">
              Copyright © Printeroids.com 2023 All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: "FooterSection",
  // Component logic
};
</script>
<style>
.ftr-list li a {
  color: white !important;
}
</style>
