<template>
  <!-- <div>
  </div> -->
  <div>
    <nav
      class="navbar navbar-expand-lg navbar-dark bg-dark main-bg top-main-menu pt-0"
    >
      <div class="container-fluid menu-container">
        <a class="navbar-brand" href="index.html">
          <img
            src="../../assets/png/logo-no-background.png"
            class="img-fluid header-logo"
            alt="Printeroids"
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto rap-nav">
            <li class="nav-item">
              <div class="d-flex flex-row bd-highlight">
                <div
                  class="p-2 bd-highlight head_fix_number _clickToCallBtn mr-2"
                >
                  <img src="../../assets/images/flag/us-flag.png" width="30" />
                  +1-866-602-9049
                </div>
  
                <!-- <div class="p-2 bd-highlight head_fix_number _clickToCallBtn">
                  <img src="../../assets/images/flag/uk-flag.png" width="30" />
                  +44-208-146-7125
                </div> -->
              </div>
            </li>
  
            <li class="nav-item">
              <a class="nav-link rap-nav main-menu" href="index.html"
                >Home <span class="sr-only">(current)</span></a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link rap-nav main-menu" href="support"
                >Support</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link rap-nav main-menu" href="blogs">Blogs</a>
            </li>
  
            <!-- only mobile view  dropdown button  -->
            <!-- <div class="dropdown show only-mobile">
              <a
                class="btn navo-button dropdown-toggle"
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Buy Now
              </a>
  
              <div
                class="dropdown-menu computeroids-dropdown view-border"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <a class="dropdown-item comUSUK SecondaryDrop" href="cart.html"
                  >Buying in US
                </a>
                <a
                  class="dropdown-item comUSUK SecondaryDrop"
                  href="cart/payment/uk.html"
                  >Buying in UK
                </a>
                <a
                  class="dropdown-item comUSUK SecondaryDrop"
                  href="cart/payment/usd.html"
                  >Other Countries</a
                >
              </div>
            </div> -->
  
            <!-- end -->
  
            <!-- Only view For Window not mobile  -->
            <!-- <li class="nav-item dropdown DropdownHide viewwindow">
              <a
                class="nav-link dropdown-toggle main-menu"
                href="cart.html"
                id="navbarDropdownMenuLink"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Buy Now
              </a>
              <div
                class="dropdown-menu computeroids-dropdown"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <a class="dropdown-item comUSUK SecondaryDrop" href="cart.html"
                  >Buying in US
                </a>
                <a
                  class="dropdown-item comUSUK SecondaryDrop"
                  href="cart/payment/uk.html"
                  >Buying in UK
                </a>
                <a
                  class="dropdown-item comUSUK SecondaryDrop"
                  href="cart/payment/usd.html"
                  >Other Countries</a
                >
              </div>
            </li> -->
  
            <!-- end -->
            <!-- <li class="nav-item">
              <a class="nav-link main-menu" href="login.html"> Login</a>
            </li> -->
          </ul>
        </div>
      </div>
    </nav>
    <router-view />
  </div>
</template>

<script type="text/javascript">
import { mapActions } from "vuex";
export default {
  data() {
    return {
      drawer: true,
    };
  },
  methods: {
    ...mapActions({
      logOut: "auth/logOut",
    }),
    logout() {
      this.logOut();
    },
  },
};
</script>
