var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('footer',{staticClass:"Bg-Footer siteFooter"},[_c('div',{staticClass:"container wrap-container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 footer-heading pt-5"},[_c('ul',{staticClass:"bull-block ftr-list"},[_c('li',{staticClass:"ftr-hrading"},[_c('a',{staticClass:"navbar-brand",attrs:{"href":"index.html"}},[_c('div',{staticClass:"rap-content d-flex align-center"},[_c('span',[_c('v-icon',{staticStyle:{"color":"#0074d9"},attrs:{"size":"40"}},[_vm._v(" mdi-printer ")])],1),_c('span',{staticStyle:{"font-size":"30px"}},[_vm._v(" PRINTEROIDS ")])])])]),_vm._m(1),_vm._m(2)])]),_vm._m(3),_vm._m(4),_vm._m(5)]),_vm._m(6)]),_vm._m(7)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid",staticStyle:{"background":"#ffb100"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 py-3 text-center"},[_c('p',{staticClass:"font-size2 ptag",staticStyle:{"font-weight":"500"}},[_vm._v(" All third party products, brands or trademarks listed above are the sole property of their respective owner. No affiliation or endorsement is intended or implied. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-2"},[_c('span',{staticClass:"hv-tr",staticStyle:{"color":"#e0e0e0","font-size":"14px"}},[_c('b',[_c('i',{staticClass:"fa fa-map-marker"})]),_vm._v(" 4365 S Mooney Blvd, Visalia, California, United States")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"my-2"},[_c('span',{staticClass:"hv-tr",staticStyle:{"color":"#e0e0e0","font-size":"14px"}},[_c('b',[_c('i',{staticClass:"fa fa-envelope"})]),_vm._v(" support@printeroids.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-2 footer-heading pt-5 footerMenu"},[_c('h2',{staticClass:"text-center"}),_c('ul',{staticClass:"bull-block ftr-list"},[_c('li',{staticClass:"ftr-hrading"},[_vm._v("Products")]),_c('li',[_c('a',{staticClass:"hv-tr",attrs:{"href":"download.html"}},[_vm._v("Try Now")])]),_c('li',[_c('a',{staticClass:"hv-tr",attrs:{"href":"cart.html"}},[_vm._v("Buy Now")])]),_c('li',[_c('a',{staticClass:"hv-tr",attrs:{"href":"support"}},[_vm._v("Support")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-3 footer-heading pt-5 footerMenu"},[_c('h2',{staticClass:"text-center"}),_c('ul',{staticClass:"bull-block ftr-list computeroids_up"},[_c('li',{staticClass:"ftr-hrading"},[_vm._v("About Us")]),_c('li',[_c('a',{staticClass:"hv-tr",attrs:{"href":"about-us"}},[_vm._v("About Us")])]),_c('li',[_c('a',{staticClass:"hv-tr",attrs:{"href":"eula.html"}},[_vm._v("EULA")])]),_c('li',[_c('a',{staticClass:"hv-tr",attrs:{"href":"contact-us"}},[_vm._v("Contact Us")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-3 footer-heading pt-5 footerMenu"},[_c('h2',{staticClass:"text-center"}),_c('ul',{staticClass:"bull-block ftr-list"},[_c('li',{staticClass:"ftr-hrading"},[_vm._v("Policies")]),_c('li',[_c('a',{staticClass:"hv-tr",attrs:{"href":"software-principles.html"}},[_vm._v("Software Principles")])]),_c('li',[_c('a',{staticClass:"hv-tr",attrs:{"href":"privacy-policies"}},[_vm._v("Privacy Policy")])]),_c('li',[_c('a',{staticClass:"hv-tr",attrs:{"href":"refund-policy"}},[_vm._v("Refund Policy")])]),_c('li',[_c('a',{staticClass:"hv-tr",attrs:{"href":"terms-conditions"}},[_vm._v("Terms of Use")])]),_c('li',[_c('a',{staticClass:"hv-tr",attrs:{"href":"dmca"}},[_vm._v("DMCA")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row",attrs:{"id":"footerSeel"}},[_c('div',{staticClass:"col-sm-2"},[_c('div',{attrs:{"id":"DigiCertClickID_0aprU8-T"}})]),_c('div',{staticClass:"col-sm-2 pt-2"},[_c('div',{attrs:{"id":"DigiCertClickID_0aprU8-T"}})]),_c('div',{staticClass:"col-sm-2 qwerty"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../../assets/images/appsteem-logo-1.png"),"width":"100","alt":"Printeroids Driver Updater"}})]),_c('div',{staticClass:"col-sm-4 qwerty"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../../assets/images/payment-2.png"),"alt":"Printeroids Driver Updater"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row copy-bg"},[_c('div',{staticClass:"col-12 text-center"},[_c('p',{staticClass:"copyright"},[_vm._v(" Copyright © Printeroids.com 2023 All rights reserved. ")])])])])
}]

export { render, staticRenderFns }